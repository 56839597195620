const tracking = `<script src='https://www.google-analytics.com/analytics.js'></script>
<script>
    var gaProperty = 'UA-77296747-9'; 
    var disableStr = 'ga-disable-' + gaProperty; 
    if (document.cookie.indexOf(disableStr + '=true') > -1) { window[disableStr] = true;} 
    function gaOptout() { document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/'; window[disableStr] = true; }
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){ (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o), m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m) })
    (window,document,'script','https://www.google-analytics.com/analytics.js','ga'); 
    ga('create', 'UA-77296747-9', 'auto'); 
    ga('set', 'anonymizeIp', true); 
    ga('send', 'pageview'); 
</script>`;


window.cookieconsent.initialise({
    layout: "oticon",
    layouts: {
      oticon:
        '<div class="cookie--hinweis">\
   {{message}}{{link}}{{compliance}}</div>'
    },
    type: "opt-out",
    content: {
      message:
        "Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Weitere Informationen zu Cookies erhalten Sie in unserer ",
      dismiss: "Cookies Akzeptieren",
      allow: "Cookies akzeptieren",
      deny: "Cookies Ablehnen",
      link: "Datenschutzerklärung",
      href: "https://www.meditrend24.de/datenschutz/"
    },
    onInitialise: function(status) {
      var type = this.options.type;
      var didConsent = this.hasConsented();
      if (type == "opt-in" && didConsent) {
        // enable cookies
        appendJS();
      }
      if (type == "opt-out" && !didConsent) {
        // disable cookies
      }
     
    },
    onStatusChange: function(status, chosenBefore) {
      var type = this.options.type;
      var didConsent = this.hasConsented();
      if (type == "opt-in" && didConsent) {
        // enable cookies
        appendJS();
      }
      if (type == "opt-out" && !didConsent) {
        // disable cookies
      }
    },
    onRevokeChoice: function() {
      var type = this.options.type;
      if (type == "opt-in") {
        // disable cookies
        appendJS();
      }
      if (type == "opt-out") {
        // enable cookies
      }
    }
  });
        
function getCookie(name) {
            var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
            return v ? v[2] : null;
        }

        
function appendJS() {
            $
              .ajax({
                url: "./includes/tracking.html",
                cache: !0
              })
              .done(function(e) {
                $("head").append(e);
              });
          }
    
(function(){    
    if(getCookie('cookieconsent_status') === 'allow'){
        appendJS();

    } else {
        console.log('KEINE COOKIES')
        $('head').append('<h1>Hallo</h1>')
    }
      })()

window.addEventListener('click', (e) => {
    if(e.target.className.indexOf('cc-allow') > -1){
        document.querySelector('head').insertAdjacentHTML('beforeend', tracking)
        location.reload()  

    } 
})