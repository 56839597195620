$(function() {

    var sozial3_BTN = $("#sozial3_BTN");
    var sozial2_BTN = $("#sozial2_BTN");

    sozial3_BTN.on("click", function(){ 
        $("#sozial03").toggleClass("grafik_active");
    });

    sozial2_BTN.on("click", function(){
        $("#sozial02").toggleClass("grafik_active");
    });
    
});