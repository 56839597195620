


var burgerBTN = document.querySelector('.burgerBTN');
var mobileNavi = document.querySelector('.navigation_desk');
var body = document.querySelector('body');

burgerBTN.addEventListener('click', function(ev) {
  this.classList.toggle('menuOpen');
  mobileNavi.classList.toggle('mobileOn');
  body.classList.toggle('noScroll');
}, false);


// var slider = document.getElementById("myRange");
// var output = document.getElementById("output");

// var elem = document.getElementById("myRange");
// var bubblesContainer = document.getElementById("Ebene_1");

// var rangeValue = function(){
//   var newValue = elem.value;
//   var target = document.getElementById("output");
//   target.innerHTML = newValue;
// }

// var finalValue = "bubble";
// var valor = elem.value;
// var hola = finalValue + valor;

// elem.addEventListener("input", rangeValue);
// bubblesContainer.classList.add(hola);

var numItems = $('.st2').length;

$(function() {

    $('#myRange').on('input change', function() {

        var element = $('#myRange');
        var value = element.val();
        var BrainSVG = $("#BrainSVG");
        var raketeSVG = $("#raketeSVG");
        var taffelSVG = $("#taffelSVG");
        var regelSVG = $("#regelSVG")
        var bottleSVG = $("#bottleSVG");
        var AppleSVG = $("#AppleSVG");
        var keySVG = $("#keySVG");
        var timerSVG = $("#timerSVG");

        /* 
            Map your rules 
        */
        if (value == 0 ) {
            raketeSVG.removeClass();
        }
        else if (value <= 13 ) {
            BrainSVG.removeClass();
            raketeSVG.addClass("range_1_8");
        }
        else if (value <= 26 ) {
            taffelSVG.removeClass();
            BrainSVG.addClass("range_2_8");
        }
        else if (value <= 39 ) {
            regelSVG.removeClass();
            taffelSVG.addClass("range_3_8");
        }
        else if (value <= 52 ) {
            AppleSVG.removeClass();
            regelSVG.addClass("range_4_8");
        }
        else if (value <= 65 ) {
            bottleSVG.removeClass();
            AppleSVG.addClass("range_5_8");
        }
        else if (value <= 78 ) {
            keySVG.removeClass();
            bottleSVG.addClass("range_6_8");
        }
        else if (value <= 90 ) {
            timerSVG.removeClass();
            keySVG.addClass("range_7_8");
        }
        else if (value <= 100 ) {
            timerSVG.addClass("range_8_8");
        }
        else {
            $('#output').text(value);  
        }

        
        
    });
});


