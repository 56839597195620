$(function() {

    $("#dropItems").hide();

    $("#dropdown").on("click", function(){
        $(this).find("#dropItems").slideToggle();
        $(this).find(".arrow-down").toggleClass("open");
    });

    var columnActive = $("#dropItems").find(".active").html();
    // alert(columnActive);

    $("#dropdown .tableActive").text(columnActive);
    
    $("#dropItems").find("div").each(function(index) {
        $(this).on("click", function(){
            // alert(index + 1);
            $("#dropItems").find("div").removeClass("active");
            $(this).addClass("active")

            var columnActive = $("#dropItems").find(".active").html();
            // alert(columnActive);

            $("#dropdown .tableActive").text(columnActive);


            $(".columns").find(".col").hide();
            $(".columns").find(".col--" + (index+1)).show();
        });
    });

});