$(function() {

var gaerete_colors_ite = $(".gaerete_colors_ite").find(".active").attr("id");
var gaerete_model_ite = $(".gaerete_model_ite").find(".active").attr("id");

var output_gaerete_model_ite = $("#output_gaerete_model_ite");
var output_gaerete_farbe_ite = $("#output_gaerete_farbe_ite");


/*DATA INFO*/

var modelName = $(".gaerete_model_ite").find(".active").attr("data-name");
var modelColor = $(".gaerete_colors_ite").find(".active").attr("data-color");


// alert(modelText);
// alert(colorText);

output_gaerete_model_ite.html(modelName);
output_gaerete_farbe_ite.html(modelColor);



    $(".gaerete_colors_ite").find("div").each(function(index) {
        $(this).on("click", function(){
            $(".gaerete_colors_ite").find("div").removeClass("active");
            $(this).addClass("active");
            var activeColor = $(".gaerete_colors_ite").find(".active").attr("id");
            var activeModel = $(".gaerete_model_ite").find(".active").attr("id");

            var activeModel_name = $(".gaerete_model_ite").find(".active").attr("data-name");
            var activeModel_material = $(".gaerete_colors_ite").find(".active").attr("data-color");

            output_gaerete_model_ite.html(activeModel_name);
            output_gaerete_farbe_ite.html(activeModel_material);

            $('.imageContainer').attr('src', 'assets/img/combinator/'+activeColor + activeModel +  '.png');

        });
    });

    $(".gaerete_model_ite").find("img").each(function(index) {
        $(this).on("click", function(){
            $(".gaerete_model_ite").find("img").removeClass("active");
            $(this).addClass("active");
            var activeColor = $(".gaerete_colors_ite").find(".active").attr("id");
            var activeModel = $(".gaerete_model_ite").find(".active").attr("id");
            
            var activeModel_name = $(".gaerete_model_ite").find(".active").attr("data-name");
            var activeModel_material = $(".gaerete_colors_ite").find(".active").attr("data-color");

            output_gaerete_model_ite.html(activeModel_name);
            output_gaerete_farbe_ite.html(activeModel_material);
            
            $('.imageContainer').attr('src', 'assets/img/combinator/'+activeColor + activeModel +  '.png');

        });
    });


});